import React from 'react';
import microsoftImg from '../../assets/image/microsoft.png';
import salesforceImg from '../../assets/image/salesforce.png';
import tableauImg from '../../assets/image/tableau.png';
import databricksImg from '../../assets/image/databricks.png';
import '../../styles/alliances.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../components/AnimationWrapper';
import Alliance from '../components/Alliance';

const partners = [
  {
    src: databricksImg,
    alt: 'Databricks Partner',
  },
  {
    src: microsoftImg,
    alt: 'Microsoft Partner',
  },
  {
    src: salesforceImg,
    alt: 'Salesforce Partner',
  },
  {
    src: tableauImg,
    alt: 'Tableau Partner',
  },
];

const Alliances = () => {
  const { t } = useTranslation();

  return (
    <section className='container mx-auto py-16' id='alliances'>
      <div>
        <AnimationWrapper from='bottom'>
          <div className='flex items-center flex-col lg:flex-row justify-between md:items-start text-center md:text-left pb-8'>
            <h3 className='text-primary text-5xl sm:text-6xl xl:text-7xl mb-8 md:mb-4 font-bold w-full lg:w-[50%]'>
              {t('alliances.title')} <br />{' '}
              <span className='text-white'>{t('alliances.subtitle')}</span>
            </h3>
            <p className='text-sm md:text-base w-full lg:w-[50%] text-text-caption'>
              {t('alliances.description1')}
              <br />
              <br />
              {t('alliances.description2')}
            </p>
          </div>
        </AnimationWrapper>
        <div className='grid grid-cols-12 mt-12 gap-y-14 gap-x-6'>
          <Alliance {...partners[0]} position={'01'} delay={0.2} />
          <Alliance {...partners[1]} position={'02'} delay={0.3} />
          <Alliance {...partners[2]} position={'03'} delay={0.4} />
          <Alliance {...partners[3]} position={'04'} delay={0.5} />
        </div>
      </div>
    </section>
  );
};

export default Alliances;
