import React from 'react';
import AnimationWrapper from './AnimationWrapper';
import LazyLoad from 'react-lazyload';

const Alliance = ({ src, alt, time, delay, position }) => {
  return (
    <div className='col-span-12 sm:col-span-6 lg:col-span-3 text-left self-stretch cursor-default'>
      <AnimationWrapper from='bottom' time={time} delay={delay}>
        <div className='relative self-stretch service'>
          <div className='service-icon-container inline-block w-[80px] h-[80px]'>
            <figure className='w-full h-full grid place-items-center bg-primary text-3xl font-bold'>
              {position}
            </figure>
          </div>
          <div className='service-container relative pb-5 pt-12 px-7 h-full flex ali min-h-[250px] '>
            <div className='alliances-box-shadow inline-block w-[80px] h-[80px]'></div>
            <figure className='grid place-items-center mx-auto'>
              <LazyLoad once height={70}>
                <img src={src} alt={alt} className='object-contain' />
              </LazyLoad>
            </figure>
          </div>
        </div>
      </AnimationWrapper>
    </div>
  );
};

export default Alliance;
