import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Company1 from '../../assets/image/svg/mastercard.svg';
import Company2 from '../../assets/image/svg/abbott.svg';
import Company3 from '../../assets/image/svg/logitech.svg';
import Company4 from '../../assets/image/svg/anglo.svg';
import Company5 from '../../assets/image/svg/quest.svg';
import AnimationWrapper from '../components/AnimationWrapper';
import LazyLoad from 'react-lazyload';

export default function PartnersSection() {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    centerMode: true,
    centerPadding: '30px',
  };

  return (
    <AnimationWrapper from='bottom'>
      <section className='container mx-auto pt-16 sm:pt-0'>
        <LazyLoad once height={150}>
          <div className='hidden md:flex justify-center gap-4 md:gap-8 lg:gap-14 pb-10'>
            <img src={Company1} alt='Company 1' />
            <img src={Company2} alt='Company 2' />
            <img src={Company3} alt='Company 3' />
            <img src={Company4} alt='Company 4' />
            <img src={Company5} alt='Company 5' />
          </div>
        </LazyLoad>
        <div className='md:hidden'>
          <Slider {...settings}>
            <div className='slide-item'>
              <LazyLoad once>
                <img src={Company1} alt='Company 1' />
              </LazyLoad>
            </div>
            <div className='slide-item'>
              <LazyLoad once>
                <img src={Company2} alt='Company 2' />
              </LazyLoad>
            </div>
            <div className='slide-item'>
              <LazyLoad once>
                <img src={Company3} alt='Company 3' />
              </LazyLoad>
            </div>
            <div className='slide-item'>
              <LazyLoad once>
                <img src={Company4} alt='Company 4' />
              </LazyLoad>
            </div>
            <div className='slide-item'>
              <LazyLoad once>
                <img src={Company5} alt='Company 5' />
              </LazyLoad>
            </div>
          </Slider>
        </div>
      </section>
    </AnimationWrapper>
  );
}
