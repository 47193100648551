import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import calendar from '../../assets/image/calendar.png';
import AnimationWrapper from '../components/AnimationWrapper';
import LazyLoad from 'react-lazyload';

export default function BlogSection() {
  const { t } = useTranslation();
  const [_blogs, setBlogs] = useState([]);
  const { language } = useI18next();
  const [blogsUrl, setBlogsUrl] = useState(
    language === 'en'
      ? 'https://blog.smarttie.ca'
      : 'https://blog.smarttie.com.mx'
  );

  useEffect(() => {
    async function _getData() {
      try {
        const data = await getData(1, 3, language);

        if (data.blogs.length === 0) {
          const spanishData = await getData(1, 3, 'es');

          setBlogs(spanishData.blogs);

          setBlogsUrl('https://blog.smarttie.com.mx');

          console.log('A');

          return;
        }

        setBlogs(data.blogs);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    _getData();
  }, [language]);

  if (_blogs.length === 0) {
    return null;
  }

  return (
    <div className='py-12'>
      <div className='container mx-auto min-h-[400px]'>
        <AnimationWrapper from='bottom'>
          <div className='flex flex-row justify-between mb-8 text-white'>
            <h2 className='text-5xl w-full sm:w-auto font-bold text-center sm:text-3xl '>
              {t('blogs.title')}{' '}
              <span className='text-primary'>{t('blogs.title2')}</span>{' '}
            </h2>
            <p className='beat text-md hidden sm:flex items-center'>
              <a href={blogsUrl} className='hover:text-primary'>
                {t('blogs.link')}
              </a>
            </p>
          </div>
        </AnimationWrapper>

        <div className='grid grid-cols-12 gap-y-7 sm:gap-x-7'>
          {_blogs.map((blog, index) => (
            <div
              className='w-full self-stretch even:last:col-start-auto sm:last:col-start-4 lg:last:col-start-auto col-span-12 sm:col-span-6 lg:col-span-4'
              key={index}
            >
              <AnimationWrapper
                from='bottom'
                delay={0.4 + index / 10}
                stretch={true}
              >
                <div className='rounded-lg shadow-md h-full flex flex-col justify-between'>
                  <LazyLoad once height={290}>
                    <a
                      href={`https://blog.smarttie.com.mx/blog/${blog.slug}`}
                      className='min-h-[40%]'
                    >
                      <img
                        src={
                          blog._embedded &&
                          blog._embedded['wp:featuredmedia'] &&
                          blog._embedded['wp:featuredmedia'].length > 0
                            ? blog._embedded['wp:featuredmedia'][0].source_url
                            : ''
                        }
                        className='w-full h-auto object-cover rounded-t-lg sm:min-h-[290px]'
                        alt="Blog's cover"
                      />
                    </a>
                  </LazyLoad>
                  <div className='p-4 flex flex-col justify-between'>
                    <h3 className='text-xl font-semibold mb-2 text-white'>
                      <a
                        href={`https://blog.smarttie.com.mx/blog/${blog.slug}`}
                        className='hover:text-primary'
                      >
                        {blog.title.rendered}
                      </a>
                    </h3>
                    <div className='flex flex-row'>
                      <p className='text-gray-400 text-sm mr-1'> by </p>
                      <p className=' text-sm mr-3 text-white'> Smarttie </p>
                      <LazyLoad once height={10}>
                        <img
                          src={calendar}
                          alt='calendar'
                          className='mr-1 w-max h-max'
                        />
                      </LazyLoad>
                      <p className='text-sm text-white'>
                        {format(new Date(blog.date), 'MMMM dd, yyyy')}
                      </p>
                    </div>
                  </div>
                </div>
              </AnimationWrapper>
            </div>
          ))}
        </div>

        <p className='text-md underline sm:hidden text-center pt-7 text-sm'>
          <a href={blogsUrl} className='hover:text-primary'>
            {t('blogs.link')}
          </a>
        </p>
      </div>
    </div>
  );
}

async function getData(page = 1, per_page = 3, language = 'en') {
  const BLOG_LINK =
    language === 'en'
      ? 'https://blog.smarttie.ca'
      : 'https://blog.smarttie.com.mx';

  const res = await fetch(
    `${BLOG_LINK}/wp-json/wp/v2/posts?_embed&page=${page}&per_page=${per_page}`
  );

  return {
    blogs: await res.json(),
    total: res.headers.get('X-WP-Total'),
    totalPages: res.headers.get('X-WP-TotalPages'),
  };
}
