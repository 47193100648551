import React from 'react';
import '../../styles/sectors.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../components/AnimationWrapper';
import Sector from '../components/Sector';

const Sectors = () => {
  const { t } = useTranslation();

  const sectors = [
    {
      title: t('sectors.sector1.title'),
      description: t('sectors.sector1.description'),
    },
    {
      title: t('sectors.sector2.title'),
      description: t('sectors.sector2.description'),
    },
    {
      title: t('sectors.sector3.title'),
      description: t('sectors.sector3.description'),
    },
  ];
  return (
    <section className='container mx-auto py-16' id='industries'>
      <div>
        <div className='flex items-center justify-center md:items-start flex-col text-center md:text-left'>
          <AnimationWrapper from='left'>
            <span className='uppercase text-primary text-xs pb-2 font-bold'>
              {t('sectors.caption')}
            </span>
          </AnimationWrapper>
          <AnimationWrapper from='left' delay={0.2}>
            <h3 className='text-4xl text-white md:text-7xl mb-8 md:mb-4 font-bold'>
              {t('sectors.title')} <br />{' '}
              <span className='text-primary'>{t('sectors.subtitle')}</span>
            </h3>
          </AnimationWrapper>
          <AnimationWrapper from='left' delay={0.3}>
            <p className='text-sm md:text-base lg:text-lg text-text-caption'>
              {t('sectors.description')}
            </p>
          </AnimationWrapper>
        </div>
        <div className='grid grid-cols-12 mt-8 gap-y-6 gap-x-6'>
          <Sector {...sectors[0]} delay={0.3} />
          <Sector {...sectors[1]} delay={0.4} />
          <Sector {...sectors[2]} delay={0.5} />
        </div>
      </div>
    </section>
  );
};

export default Sectors;
