import React from 'react';
import checkIcon from '../../assets/image/check-icon.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from './AnimationWrapper';
import LazyLoad from 'react-lazyload';

const Sector = ({ title, description, time, delay }) => {
  const { t } = useTranslation();

  return (
    <div className='col-span-12 sm:col-span-6 lg:col-span-4 last:col-start-auto self-stretch sm:last:col-start-4 lg:last:col-start-auto cursor-default'>
      <AnimationWrapper from='bottom' time={time} delay={delay} stretch={true}>
        <div className='sector-container relative flex flex-col justify-between h-full'>
          <div className='w-full grid place-content-center absolute -top-20'>
            <div className='service-box-shadow min-w-[100px]'></div>
          </div>
          <h3 className='text-center text-white font-bold text-4xl mb-6'>
            {title}
          </h3>
          <div className='flex gap-2 justify-center px-3 mb-6'>
            <span className='flex justify-center min-w-[20px] w-[10%] min-h-[20px]'>
              <LazyLoad once height={20}>
                <img
                  src={checkIcon}
                  alt='check icon'
                  className='w-[20px] h-[20px]'
                />
              </LazyLoad>
            </span>
            <p className='inline-block w-[88%] text-white text-sm'>
              {description}
            </p>
          </div>
          <button className='bg-transparent font-semibold text-white hover:text-white border py-2 px-4 border-primary w-full rounded-lg inline-block text-center relative overflow-hidden'>
            <div className='w-full h-full grid place-items-center absolute top-0 left-0'>
              <div className='bg-animation-center rounded-full'></div>
            </div>
            <span className='relative z-[2]'>{t('sectors.buttonText')}</span>
          </button>
        </div>
      </AnimationWrapper>
    </div>
  );
};

export default Sector;
