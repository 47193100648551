import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../components/AnimationWrapper';
import bgHero from '../../assets/image/hero-bg.jpg';
import bgHeroMobile from '../../assets/image/hero-bg-mobile.jpg';
import playIcon from '../../assets/image/svg/play-icon.svg';
import ModalVideo from '../components/ModalVideo';
import closeIcon from '../../assets/image/icon-x.svg';
import ReactPlayer from 'react-player/youtube';
import { useLocation } from '@reach/router';

const Hero = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadVideo, setLoadVideo] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const video =
    location.pathname === '/'
      ? 'https://www.youtube.com/watch?v=weMLH_1JrKg'
      : 'https://www.youtube.com/watch?v=VksdOHPcLcc';

  return (
    <>
      <section className='min-h-[calc(100vh-35px)] max-h-[1440px] grid place-items-center relative'>
        <div className='container mx-auto pt-5'>
          <section>
            <article className='max-w-[1000px] mx-auto flex flex-col gap-4 md:gap-10 item-center justify-center text-center relative z-[1]'>
              <AnimationWrapper from='left' time={0.3} delay={0}>
                <h1 className='text-white text-2xl md:text-2xl lg:text-4xl font-bold max-w-[800px] mx-auto'>
                  <span className='text-primary'>Smarttie: </span>
                  {t('hero.title2')}
                </h1>
                <p className='font-bold pt-2 text-sm md:text-lg'>
                  {t('hero.title')}
                </p>
              </AnimationWrapper>

              <AnimationWrapper from='left' time={0.3} delay={0}>
                <h2 className='text-white text-base md:text-xl lg:text-2xl font-bold mx-auto'>
                  {t('hero.subtitle1')}
                </h2>
                <p className='text-white text-sm md:text-base max-w-[700px] mx-auto'>
                  {t('hero.subtitle2')}
                </p>
              </AnimationWrapper>
            </article>
            <div className='relative z-[1] pt-4 md:pt-10'>
              <AnimationWrapper from='bottom' time={0.3} delay={0}>
                <figure
                  className='mx-auto video-play-button w-16 h-16 grid place-items-center border border-white rounded-full cursor-pointer hover:scale-105 hover:border-primary'
                  onClick={() => {
                    setIsModalVisible(true);
                    setLoadVideo(true);
                  }}
                >
                  <img
                    src={playIcon}
                    alt='play-icon'
                    className='pl-1 w-6 h-6'
                  />
                </figure>
              </AnimationWrapper>
            </div>
          </section>
          <div className='absolute w-full h-full max-h-[1440px] top-0 left-0 z-0'>
            <picture>
              {/* Mobile source for screen with less than 700px */}
              <source
                media='(max-width: 700px)'
                srcSet={bgHeroMobile}
                className='w-full h-full object-cover object-center'
              />
              <img
                src={bgHero}
                alt='hero-bg'
                className='w-full h-full object-cover object-center'
              />
            </picture>
          </div>
        </div>

        <div className='absolute w-full bottom-0 left-0 right-0 flex justify-center pb-8 md:pb-10'>
          <AnimationWrapper from='bottom' time={0.3} delay={0}>
            <Link
              to='#about'
              className='beat text-white text-sm md:text-lg z-[1] flex flex-col justify-center items-center text-center gap-1 md:hover:text-primary'
            >
              <span className='relative z-[2]'>{t('hero.cta')}</span>
              <svg
                className='w-2.5 h-2.5'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 10 6'
                id='about'
              >
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='m1 1 4 4 4-4'
                />
              </svg>
            </Link>
          </AnimationWrapper>
        </div>
      </section>
      <ModalVideo
        isVisible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
      >
        <div className='relative w-full h-full grid place-items-center'>
          <button
            className='absolute top-5 left-5 text-white text-6xl cursor-pointer z-[1] hover:scale-[1.1] opacity-90'
            onClick={() => setIsModalVisible(false)}
          >
            <img src={closeIcon} alt='play-icon' loading='lazy' />
          </button>
          {(loadVideo || isModalVisible) && (
            <ReactPlayer
              url={video}
              width='100%'
              height=''
              className='h-[50vh] md:h-[80vh]'
              controls
              volume={0.5}
              playing={isModalVisible}
              onEnded={() => setIsModalVisible(false)}
            />
          )}
        </div>
      </ModalVideo>
    </>
  );
};

export default Hero;
