import React from 'react';
import Globe from '../../assets/image/globe.png';
import locationIcon from '../../assets/image/location.png';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import '../../styles/reach.css';
import AnimationWrapper from '../components/AnimationWrapper';
import LazyLoad from 'react-lazyload';

export default function ReachSection() {
  const { t } = useTranslation();
  return (
    <div
      className=' container mx-auto flex items-center justify-center pb-16 pt-8'
      id='locations'
    >
      <div className='flex flex-col md:flex-row max-w-full items-center justify-between text-white'>
        <div className='max-w-md md:mx-auto md:px-6 lg:px-0 md:order-last md:text-center md:w-[50%] pb-6 md:pb-0'>
          <AnimationWrapper from='right'>
            <p className='text-4xl lg:text-5xl xl:text-6xl md:text-start text-center font-bold mb-0'>
              <span className='text-primary'>{t('reach.title')}</span>
              <br />
              {t('reach.subtitle')}
            </p>
          </AnimationWrapper>
          <AnimationWrapper from='right' delay={0.3}>
            <p className='pt-2 text-xs lg:text-base xl:text-lg md:pt-4 text-center md:text-start mb-0'>
              {t('reach.description')}
            </p>
          </AnimationWrapper>
        </div>
        <div className='md:order-first md:w-[50%] md:px-6'>
          <AnimationWrapper from='bottom' time={0.6}>
            <LazyLoad once height={250}>
              <img
                src={Globe}
                alt='globe'
                className='globe mb-0 md:mb-6 w-full  max-w-xs md:max-w-none'
              />
            </LazyLoad>
          </AnimationWrapper>
          <div className='grid grid-cols-12 pt-6 gap-y-2'>
            <div className='text-sm col-span-6 xl:col-span-3'>
              <AnimationWrapper from='bottom' delay={0.1}>
                <div className='flex justify-center items-center gap-1'>
                  <LazyLoad once height={16}>
                    <img
                      src={locationIcon}
                      alt='location icon'
                      className='w-4 h-4'
                    />
                  </LazyLoad>
                  <span className='whitespace-nowrap'>Guadalajara</span>
                </div>
              </AnimationWrapper>
            </div>
            <div className='text-sm col-span-6 xl:col-span-2'>
              <AnimationWrapper from='bottom' delay={0.2}>
                <div className='flex justify-center items-center gap-1'>
                  <LazyLoad once height={16}>
                    <img
                      src={locationIcon}
                      alt='location icon'
                      className='w-4 h-4'
                    />
                  </LazyLoad>
                  <span className='whitespace-nowrap'>Vancouver </span>
                </div>
              </AnimationWrapper>
            </div>
            <div className='text-sm col-span-6 xl:col-span-4'>
              <AnimationWrapper from='bottom' delay={0.3}>
                <div className='flex justify-center items-center gap-1'>
                  <LazyLoad once height={16}>
                    <img
                      src={locationIcon}
                      alt='location icon'
                      className='w-4 h-4'
                    />
                  </LazyLoad>
                  <span className='whitespace-nowrap'>Ciudad de México</span>
                </div>
              </AnimationWrapper>
            </div>
            <div className='text-sm col-span-6 xl:col-span-3'>
              <AnimationWrapper from='bottom' delay={0.4}>
                <div className='flex justify-center items-center gap-1'>
                  <LazyLoad once height={16}>
                    <img
                      src={locationIcon}
                      alt='location icon'
                      className='w-4 h-4'
                    />
                  </LazyLoad>
                  <span className='whitespace-nowrap'>San Francisco</span>
                </div>
              </AnimationWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
