import React, { useState } from 'react';
import CustomSelect from '../components/CustomSelect';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import '../../styles/form.css';
import AnimationWrapper from '../components/AnimationWrapper';
import Check from '../../assets/image/svg/circle-check.svg';

export default function FormSection() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [sector, setSector] = useState('');
  const [message, setMessage] = useState('');
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [sectorTouched, setSectorTouched] = useState(false);
  const [messageTouched, setMessageTouched] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const { t } = useTranslation();

  const validateName = () => {
    if (name && /\d/.test(name)) {
      return t('form.validations.error4');
    }
    return '';
  };

  const validateEmail = () => {
    if (email && !/^\S+@\S+\.\S+$/.test(email)) {
      return t('form.validations.error5');
    }
    return '';
  };

  const validateMessage = () => {
    const minWords = message.split(/\s+/).filter((word) => word.length > 0);
    if (minWords.length < 20) {
      return t('form.validations.error6');
    }
    const maxWords = message.split(/\s+/).filter((word) => word.length > 0);
    if (maxWords.length > 500) {
      return t('form.validations.error7');
    }
    return '';
  };

  const isFormValid = () => {
    return (
      name.trim() !== '' &&
      email.trim() !== '' &&
      sector.trim() !== '' &&
      message.trim() !== '' &&
      !validateName() &&
      !validateEmail() &&
      !validateMessage()
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSending(true);

    try {
      const response = await fetch(`https://api.smarttie.ca`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'emHEzTvF1]0B=[u.Q]t$%%da/9P7q-',
        },
        body: JSON.stringify({
          name,
          email,
          sector,
          message,
          origin: 'contact',
        }),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setName('');
      setEmail('');
      setSector('');
      setMessage('');
      setNameTouched(false);
      setEmailTouched(false);
      setSectorTouched(false);
      setMessageTouched(false);
      setIsSending(false);
    }
    setFormSubmitted(true);
  };

  return (
    <div
      className="flex items-center justify-center bg-[url('../assets/image/form-bg.jpg')] bg-no-repeat bg-cover"
      id='contact'
    >
      <div className='container mx-auto flex flex-col lg:flex-row justify-between my-14 items-center'>
        <AnimationWrapper from='left'>
          <div className='text-center md:text-start p-6 md:mr-4 md:mb-0 '>
            <h1 className='text-4xl md:max-w-2xl md:text-5xl xl:text-6xl font-bold mb-4 text-white line'>
              {t('form.cta')}
            </h1>
            <p className='text-lg md:text-xl md:max-w-md text-white mb-8'>
              {t('form.explanation')}
            </p>
          </div>
        </AnimationWrapper>
        <div className='max-w-xl md:ml-4 w-full flex-auto'>
          <AnimationWrapper from='right' delay={0.3}>
            {formSubmitted ? (
              <div className='flex flex-col justify-center items-center p-8 rounded-lg bg-white/10'>
                <img src={Check} alt='check' />
                <p className='text-white text-3xl text-center font-bold'>
                  {t('form.submitMessage')}
                </p>
              </div>
            ) : (
              <form
                className='p-8 rounded-lg bg-white/10'
                onSubmit={handleSubmit}
              >
                <div className='mb-4'>
                  <label
                    htmlFor='name'
                    className='block text-lg text-white mb-2'
                  >
                    {t('form.name')}
                  </label>
                  <input
                    type='text'
                    id='name'
                    placeholder={
                      !nameTouched
                        ? t('form.name')
                        : name
                        ? validateName()
                        : t('form.validations.error1')
                    }
                    name='name'
                    className={`w-full md:w-full px-4 py-2 border rounded-md text-black ${
                      (!name || validateName()) && nameTouched
                        ? 'border-red-500'
                        : ''
                    }`}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameTouched(true);
                    }}
                  />
                  {nameTouched && validateName() && (
                    <p className='text-pink-300'>{validateName()}</p>
                  )}
                </div>
                <div className='mb-4'>
                  <label
                    htmlFor='email'
                    className='block text-lg text-white mb-2'
                  >
                    {t('form.email')}
                  </label>
                  <input
                    type='email'
                    id='email'
                    placeholder={
                      !emailTouched
                        ? t('form.email')
                        : email
                        ? validateEmail()
                        : t('form.validations.error2')
                    }
                    name='email'
                    className={`w-full md:w-full px-4 py-2 border rounded-md text-black ${
                      (!email || validateEmail()) && emailTouched
                        ? 'border-red-500'
                        : ''
                    }`}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailTouched(true);
                    }}
                  />
                  {emailTouched && validateEmail() && (
                    <p className='text-pink-300'>{validateEmail()}</p>
                  )}
                </div>
                <div className='mb-4'>
                  <label
                    htmlFor='sector'
                    className='block text-white text-lg mb-2'
                  >
                    {t('form.sector')}
                  </label>
                  <CustomSelect
                    id='sector'
                    name='sector'
                    placeholder={!sectorTouched ? t('form.sector') : sector}
                    options={[
                      ['Finance', t('form.sectorOptions.finance')],
                      ['Retail', t('form.sectorOptions.retail')],
                      ['Pharma', t('form.sectorOptions.pharma')],
                      ['Otros', t('form.sectorOptions.others')],
                    ]}
                    value={sector}
                    setValue={setSector}
                    onChange={() => setSectorTouched(true)}
                  />
                </div>
                <div className='mb-4'>
                  <label
                    htmlFor='message'
                    className='block v text-white text-lg mb-2'
                  >
                    {t('form.message')}
                  </label>
                  <textarea
                    id='message'
                    placeholder={
                      !messageTouched
                        ? t('form.message')
                        : message
                        ? validateMessage()
                        : t('form.validations.error1')
                    }
                    name='message'
                    rows='4'
                    className={`w-full md:w-full px-4 py-2 border rounded-md text-black ${
                      (!message || validateMessage()) && messageTouched
                        ? 'border-red-500'
                        : ''
                    }`}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setMessageTouched(true);
                    }}
                  ></textarea>
                  {messageTouched && validateMessage() && (
                    <p className='text-pink-300'>{validateMessage()}</p>
                  )}
                </div>
                <button
                  type='submit'
                  className={`w-full md:w-auto px-4 py-2 rounded-full text-white ${
                    isFormValid() && !isSending
                      ? 'bg-emerald-400 hover:bg-emerald-400/70'
                      : 'bg-gray-400 cursor-not-allowed'
                  }`}
                  disabled={!isFormValid()}
                >
                  {t('form.submit')}
                </button>
              </form>
            )}
          </AnimationWrapper>
        </div>
      </div>
    </div>
  );
}
