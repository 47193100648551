import React from 'react';
import AnimationWrapper from './AnimationWrapper';
import LazyLoad from 'react-lazyload';

const Service = ({ icon, title, description, time, delay }) => {
  return (
    <div className='col-span-12 sm:col-span-6 lg:col-span-4 w-full text-left relative self-stretch justify-self-stretch service cursor-default'>
      <AnimationWrapper from='bottom' time={time} delay={delay} stretch={true}>
        <LazyLoad once height={80}>
          <div className='service-icon-container inline-block w-[80px] h-[80px]'>
            <figure className='w-full h-full grid place-items-center'>
              <img src={icon} alt={title} className='max-w-[50%] max-h-[50%]' />
            </figure>
          </div>
        </LazyLoad>
        <div className='service-container relative pb-5 pt-12 px-7 h-full'>
          <div className='box-shadow inline-block w-[80px] h-[80px]'></div>
          <h3 className='text-base font-bold text-white my-2'>{title}</h3>
          <p className='text-gray-400 text-sm'>{description}</p>
        </div>
      </AnimationWrapper>
    </div>
  );
};

export default Service;
