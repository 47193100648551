import React from 'react';
import '../../styles/services.css';
import monitorIcon from '../../assets/image/services/monitor-icon.svg';
import caseIcon from '../../assets/image/services/case-icon.svg';
import chartIcon from '../../assets/image/services/chart-icon.svg';
import cmdIcon from '../../assets/image/services/cmd-icon.svg';
import lightIcon from '../../assets/image/services/light-icon.svg';
import paperIcon from '../../assets/image/services/paper-icon.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../components/AnimationWrapper';
import Service from '../components/Service';

const Services = () => {
  const { t } = useTranslation();

  const services = [
    {
      icon: monitorIcon,
      title: t('services.service1.title'),
      description: t('services.service1.description'),
    },
    {
      icon: cmdIcon,
      title: t('services.service2.title'),
      description: t('services.service2.description'),
    },
    {
      icon: lightIcon,
      title: t('services.service3.title'),
      description: t('services.service3.description'),
    },
    {
      icon: paperIcon,
      title: t('services.service4.title'),
      description: t('services.service4.description'),
    },
    {
      icon: chartIcon,
      title: t('services.service5.title'),
      description: t('services.service5.description'),
    },
    {
      icon: caseIcon,
      title: t('services.service6.title'),
      description: t('services.service6.description'),
    },
  ];

  return (
    <section className='container mx-auto pb-16 pt-4' id='services'>
      <div className='flex justify-center items-center flex-col text-center'>
        <AnimationWrapper from='bottom'>
          <h3 className='font-bold text-5xl text-white my-6'>
            {t('services.title')}{' '}
            <span className='text-primary'>{t('services.title2')}</span>
          </h3>
        </AnimationWrapper>
        <AnimationWrapper from='bottom' time={0.6}>
          <p className='max-w-[1000px] text-white text-sm md:text-base lg:text-lg'>
            {t('services.description')}
          </p>
        </AnimationWrapper>

        <div className='grid grid-cols-12 mt-14 gap-y-14 w-full sm:gap-x-8'>
          <Service {...services[0]} delay={0.2} />
          <Service {...services[1]} delay={0.3} />
          <Service {...services[2]} delay={0.4} />
          <Service {...services[3]} delay={0.2} />
          <Service {...services[4]} delay={0.3} />
          <Service {...services[5]} delay={0.4} />
        </div>
        <div className='col-span-12 md:col-span-6 lg:col-span-4 text-left relative'></div>
      </div>
    </section>
  );
};

export default Services;
