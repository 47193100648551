import React, { useEffect } from 'react';

const ModalVideo = ({ isVisible, children, onClose }) => {
  // Prevent scrolling when modal is open
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isVisible]);

  const handleModalClose = (e) => {
    if (e.target.classList.contains('modal-video')) {
      onClose();
    }
  };

  return (
    <div
      className='modal-video w-lvw h-lvh bg-background-primary/90 backdrop-blur-sm fixed top-0 left-0 z-[99] grid place-items-center'
      onClick={handleModalClose}
      style={{ display: isVisible ? 'grid' : 'none' }}
    >
      {children}
    </div>
  );
};

export default ModalVideo;
