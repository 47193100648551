import React, { useRef } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import aboutImg from '../../assets/image/about-bg.svg';
import '../../styles/about.css';
import AnimationWrapper from '../components/AnimationWrapper';
import useInView from '../../hooks/useInView';
import { StaticImage } from 'gatsby-plugin-image';
import LazyLoad from 'react-lazyload';
import CounterText from '../components/CounterText';

export default function AboutSection() {
  const { t } = useTranslation();
  const counter1 = useRef(null);
  const counter2 = useRef(null);
  const counter3 = useRef(null);
  const counter4 = useRef(null);
  const isInViewCounter1 = useInView(counter1, { once: true });
  const isInViewCounter2 = useInView(counter2, { once: true });
  const isInViewCounter3 = useInView(counter3, { once: true });
  const isInViewCounter4 = useInView(counter4, { once: true });

  return (
    <div className='pt-0 sm:pt-14 pb-16'>
      <section className='container mx-auto mt-0 sm:mt-5'>
        <div id='stats' className='py-10 sm:py-10'>
          <div className='mx-auto max-w-7xl px-6 lg:px-8'>
            <div className='grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-x-8 gap-y-16 text-center'>
              <AnimationWrapper from='bottom' delay={0.1}>
                <div className='mx-auto flex max-w-xs flex-col gap-y-4'>
                  <div className='text-base leading-7 text-white'>
                    {t('about.experience1')}
                  </div>
                  <div className='order-first text-5xl font-semibold tracking-tight text-white sm:text-7xl xl:text-8xl'>
                    <span className='text-primary-about'>+</span>

                    <div ref={counter1} className='inline-block'>
                      {isInViewCounter1 && (
                        <CounterText time={1.5} from={0} to={9} />
                      )}
                    </div>
                  </div>
                </div>
              </AnimationWrapper>

              <AnimationWrapper from='bottom' delay={0.2}>
                <div className='mx-auto flex max-w-xs flex-col gap-y-4'>
                  <div className='text-base leading-7 text-white'>
                    {t('about.experience2')}
                  </div>
                  <div className='order-first text-5xl font-semibold tracking-tight text-white sm:text-7xl xl:text-8xl'>
                    <span className='text-primary-about'>+</span>
                    <div ref={counter2} className='inline-block'>
                      {isInViewCounter2 && (
                        <CounterText time={1.5} from={0} to={45} />
                      )}
                    </div>
                  </div>
                </div>
              </AnimationWrapper>

              <AnimationWrapper from='bottom' delay={0.3}>
                <div className='mx-auto flex max-w-xs flex-col gap-y-4'>
                  <div className='text-base leading-7 text-white'>
                    {t('about.experience3')}
                  </div>
                  <div className='order-first text-5xl font-semibold tracking-tight text-white sm:text-7xl xl:text-8xl'>
                    <span className='text-primary-about'>+</span>
                    <div ref={counter3} className='inline-block'>
                      {isInViewCounter3 && (
                        <CounterText time={1.5} from={0} to={30} />
                      )}
                    </div>
                  </div>
                </div>
              </AnimationWrapper>

              <AnimationWrapper from='bottom' delay={0.4}>
                <div className='mx-auto flex max-w-xs flex-col gap-y-4'>
                  <div className='text-base leading-7 text-white'>
                    {t('about.experience4')}
                  </div>
                  <div className='order-first text-5xl font-semibold tracking-tight text-white sm:text-7xl xl:text-8xl'>
                    <span className='text-primary-about'>+</span>
                    <div ref={counter4} className='inline-block'>
                      {isInViewCounter4 && (
                        <CounterText time={1.5} from={0} to={50} />
                      )}
                    </div>
                  </div>
                </div>
              </AnimationWrapper>
            </div>
          </div>
        </div>
        <AnimationWrapper from='bottom' delay={0.4}>
          <div className='flex justify-center py-10 text-xl font-medium text-white max-w-7xl mx-auto'>
            <p className='text-center max-w-[900px]'>
              {t('about.description')}
            </p>
          </div>
        </AnimationWrapper>

        <AnimationWrapper from='bottom' delay={0.4}>
          <div className='hidden lg:block w-full'>
            <LazyLoad once height={380}>
              <img
                src={aboutImg}
                alt='About img'
                className='object-fill rounded-lg'
              />
            </LazyLoad>
          </div>
        </AnimationWrapper>
      </section>
      <AnimationWrapper from='bottom' delay={0.4}>
        <LazyLoad once height={200}>
          <div className='lg:hidden w-full'>
            <StaticImage
              src='../../assets/image/about-bg.svg'
              alt='About img'
              className='object-fill rounded-lg'
            />
          </div>
        </LazyLoad>
      </AnimationWrapper>
    </div>
  );
}
